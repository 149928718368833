import React from 'react';
import PropTypes from 'prop-types';
import { SwiperContainer, Container } from './Swiper.styles';

export const Swiper = ({ children, id }) => (
  <Container>
    <SwiperContainer id={`swiper__element_${id}`}>{children}</SwiperContainer>
  </Container>
);

Swiper.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default Swiper;
