import { BrowserClient, Hub } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  PROD_ENVIRONMENT,
  DEV_ENVIRONMENT,
  STG_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  TEST_ENVIRONMENT,
} from '@appscore/web-constants/environments';

const CONSOLE_ENVIRONMENTS = [
  DEV_ENVIRONMENT,
  STG_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  TEST_ENVIRONMENT,
];

let customHub = null;
let useConsole = false;
let isLocal = false;

export const consoleLogger = {
  tags: {},
  printTags: () => {
    const keys = Object.keys(consoleLogger.tags);
    if (!keys.length) return 'none';

    const tags = consoleLogger.tags;
    return `${keys.map((key) => {
      const tag = tags[key] ? JSON.stringify(tags[key]) : tags[key];
      return `\n\t[${key}]: ${tag}`;
    })}`;
  },
  setTag: (key, value) => {
    consoleLogger.tags[key] = value;
  },
  logError: (error) => {
    console.info(`\n[TAGS]:${consoleLogger.printTags()}`);
    console.error(error);
  },
  logMessage: (message) =>
    console.info(`\n[TAGS]:${consoleLogger.printTags()}\n[MESSAGE]: ${message}`),
};

const logger = {
  init: ({ dsn, environment }) => {
    useConsole = CONSOLE_ENVIRONMENTS.includes(environment);
    if (environment === LOCAL_ENVIRONMENT) {
      isLocal = true;
      return;
    }

    const isLive = environment === PROD_ENVIRONMENT;
    const client = new BrowserClient({
      dsn,
      environment,
      integrations: [
        new Integrations.BrowserTracing({
          _metricOptions: {
            _reportAllChanges: true,
          },
          beforeNavigate: (context) => ({
            ...context,
          }),
        }),
      ],
      release: `${APP_NAME}@${APP_VERSION}`,
      tracesSampleRate: isLive ? 0.1 : 1,
      sampleRate: isLive ? 0.1 : 1,
    });

    customHub = new Hub(client);
    return customHub;
  },
  captureMessage: (message) => {
    if (useConsole) consoleLogger.logMessage(message);
    if (isLocal) return;

    if (!customHub) return null;
    customHub.run((hub) => hub.captureMessage(message));
  },
  captureException: (ex) => {
    if (useConsole) consoleLogger.logError(ex);
    if (isLocal) return;

    if (!customHub) return null;
    customHub.run((hub) => hub.captureException(ex));
  },
  setTag: (key, value) => {
    if (useConsole) consoleLogger.setTag(key, value);
    if (isLocal) return;

    if (!customHub) {
      console.warn('Cannot set tags before hub initialization');
    } else {
      customHub.setTag(key, value);
    }
  },
  setUser: (user) => {
    if (useConsole) consoleLogger.setTag('user', user);
    if (isLocal) return;

    if (!customHub) {
      console.warn('Cannot set user before hub initialization');
    } else {
      customHub.setUser(user);
    }
  },
};

Object.freeze(logger);

export { logger };
