import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tab from './components/Tab/Tab';
import { Container } from './Tabs.styles';
import Swiper from './components/Swiper/Swiper';

export const Tabs = ({ tabs, onSelect, tabSelectedId, swipeable = false }) => {
  const [selectedTab, setSelectedTab] = useState(tabSelectedId);
  const tabsWidth = useMemo(() => (swipeable ? null : 100 / tabs.length), [swipeable, tabs]);

  const onClick = useCallback(
    (id) => {
      setSelectedTab(id);
      onSelect(id);
    },
    [onSelect],
  );

  useEffect(() => {
    if (!(tabSelectedId === null || tabSelectedId === undefined)) {
      setSelectedTab(tabSelectedId);
    }
  }, [tabSelectedId]);

  return (
    <Container>
      <Swiper id="tabs" enabled={swipeable}>
        {tabs.map((tab) => (
          <Tab
            width={tabsWidth}
            onClick={onClick}
            key={tab.id}
            id={tab.id}
            selected={selectedTab === tab.id}
            text={tab.name}
          />
        ))}
      </Swiper>
    </Container>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabSelectedId: PropTypes.string,
  swipeable: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default Tabs;
