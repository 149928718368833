export const KEYS = {
  FIND_DETAIL_CLICKED: 'find_detail_mweb.clicked',
  FIND_DETAIL_SUGGESTIONS_SHOWN: 'find_detail_mweb_suggestions.shown',
};

export const TRACKING_PROPS = {
  PERFORM_SEARCH: 'perform_search',
  CHANGE_TAB: 'change_tab',
  SEARCH_RECENT: 'search_recent',
  SEARCH_AUTOCOMPLETE: 'search_autocomplete',
  SEARCH_BUTTON: 'search_button',
  ORIGIN_FIND_DETAIL_MWEB_SCREEN: 'find_detail_mweb_screen',
  BUSINESS_TYPE_RESTAURANT: 'RESTAURANT',
  BUSINESS_TYPE_GROCERIES: 'GROCERIES',
  NOT_SET: '(not set)',
};

export const defaultSearchClickedTrackingProps = {
  action: TRACKING_PROPS.PERFORM_SEARCH,
  searchType: TRACKING_PROPS.NOT_SET,
  businessType: TRACKING_PROPS.NOT_SET,
  origin: TRACKING_PROPS.ORIGIN_FIND_DETAIL_MWEB_SCREEN,
  position: TRACKING_PROPS.NOT_SET,
  autocompleteSuggestionQuantity: TRACKING_PROPS.NOT_SET,
  searched: TRACKING_PROPS.NOT_SET,
};
