export default function debounce(fn, ms = 0) {
  let timeoutId;

  function debouncedFunction(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  }

  debouncedFunction.cancel = function cancelDebouncedFunction() {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
}
