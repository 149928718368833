import messages from './messages/messages';

export const BUSINESS_TYPES = {
  RESTAURANT: 'RESTAURANT',
  GROCERIES: 'GROCERIES',
};

export const businessTypesMap = new Map([
  [BUSINESS_TYPES.RESTAURANT, messages.restaurantTitle],
  [BUSINESS_TYPES.GROCERIES, messages.groceriesTitle],
]);

export const maxAmountRecentSearches = 6;
