import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  background: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
`;

export const Div = styled.div`
  padding: 16px;
  display: flex;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  align-items: center;
  box-shadow: 0 8px 16px -12px rgba(16, 4, 35, 0.3);
  position: relative;
`;

export const Form = styled.form`
  width: 100%;
  align-items: center;
`;

export const Input = styled.div`
  display: flex;
  box-shadow: 0 8px 16px -12px rgba(16, 4, 35, 0.3);
  transition: box-shadow 0.1s ease-in;
  font-family: Segma, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4c4359;
  background-color: #eae3e3;
  border: 1px solid #eae3e3;
  border-radius: 40px;
  align-items: center;
  margin: 0;
  padding: 0px;
  padding-right: 12px;
  padding-left: 16px;
  box-sizing: border-box;
  height: 38px;

  &:active {
    box-shadow: none;
  }
`;

export const BackButton = styled.div`
  padding: 0px;
  padding-top: 12px;
  padding-left: 12px;
  max-width: fit-content;
`;

export const InputBox = styled.input`
  background-color: #eae3e3;
  border: none;
  color: #100423;
  font-family: Segma, sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  margin: 0px;
  margin-right: 10px;
  margin-left: 10px;
`;

export const IconDiv = styled.div`
  margin-top: 2px;
`;

export const DivTab = styled.div`
  box-shadow: 0 8px 16px -12px rgba(16, 4, 35, 0.3);
  position: relative;
`;

export const CircleDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: #dbdade;
  border-radius: 50%;
`;

export const ScreenImage = styled.img`
  display: block;
  margin-top: 98px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
`;

export const ScreenText = styled.p`
  font-family: Segma, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  width: 292px;
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const RecentSearchDiv = styled.div`
  padding: 16px;
  padding-bottom: 0px;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

export const RecentSearchTitle = styled.p`
  font-family: Segma, sans-serif;
  font-size: 16px;
  font-weight: 800;
  margin: 0;
`;

export const SuggestionDiv = styled.div`
  padding: 20px;
  padding-right: 0px;
  padding-left: 0px;
  margin: 0px;
  margin-right: 16px;
  margin-left: 16px;
  display: flex;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  align-items: center;
  border-bottom: 1px solid #dbdade;
`;

export const SpanRegularTitle = styled.span`
  font-family: Segma, sans-serif;
  font-size: 14px;
  font-weight: 400;
`;

export const SpanBoldTitle = styled.span`
  font-family: Segma, sans-serif;
  font-size: 14px;
  font-weight: 700;
`;

export const ItemTitle = styled.div`
  margin: 0;
  width: 100%;
  color: #100423;
  margin-left: 12px;
  margin-right: 80px;
`;

export const ResultsDiv = styled.div`
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  display: block;
`;
