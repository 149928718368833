import React from 'react';
import PropTypes from 'prop-types';
import erSearch from '../../../assets/icons/error/error_noSearchResults.svg';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages/messages';

import { BUSINESS_TYPES } from '../../../constants';

import { ScreenImage, ScreenText } from '../../../FindDetail.styles';

export const NoResultsPlaceholder = ({ businessType }) => {
  const getNoResultsText = () => {
    if (businessType === BUSINESS_TYPES.RESTAURANT)
      return (
        <FormattedMessage {...messages.restaurantNoSuggestionResults}>
          {(text) => text}
        </FormattedMessage>
      );
    if (businessType === BUSINESS_TYPES.GROCERIES)
      return (
        <FormattedMessage {...messages.groceriesNoSuggestionResults}>
          {(text) => text}
        </FormattedMessage>
      );
    return (
      <FormattedMessage {...messages.defaultNoSuggestionResults}>{(text) => text}</FormattedMessage>
    );
  };
  return (
    <>
      <ScreenImage src={erSearch} alt="Global Error" />
      <ScreenText>{getNoResultsText()}</ScreenText>
    </>
  );
};

NoResultsPlaceholder.propTypes = {
  businessType: PropTypes.string.isRequired,
};
