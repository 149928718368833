import axios from 'axios';
import { logger } from '@app/logger';
import { getSuggestionsSearch } from '../../urls/urls';

export const fetchSuggestions = async (business_type, ngram) => {
  try {
    const suggestionResponse = await axios.get(getSuggestionsSearch, {
      timeout: 10000,
      params: {
        ngram,
        business_type,
      },
    });
    return suggestionResponse.data?.suggestions?.items[0]?.items;
  } catch (error) {
    logger.captureException(error);
    return [];
  }
};
