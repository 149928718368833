import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';
import { logger } from '@app/logger';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  logger.captureException(error);

  return (
    <ThemeProvider theme={fenixTheme}>
      <BoxMessage
        variant="quiet"
        type="error"
        title="Error"
        message="Tuvimos un problema"
        onClose={() => {}}
        {...props}
      />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
