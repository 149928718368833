import { TRACKING_PROPS, KEYS } from './constants';

const createTrackSuggestionShown =
  (tracker) =>
  ({ searchUserInput, businessType, suggestionsLength }) => {
    tracker.track(KEYS.FIND_DETAIL_SUGGESTIONS_SHOWN, {
      businessType,
      origin: TRACKING_PROPS.ORIGIN_FIND_DETAIL_MWEB_SCREEN,
      searchUserInput,
      autocompleteSuggestionQuantity: suggestionsLength || 0,
    });
  };

const createSearchClickedTracker = (tracker) => ({
  defaultProps: {
    action: TRACKING_PROPS.PERFORM_SEARCH,
    searchType: TRACKING_PROPS.NOT_SET,
    businessType: TRACKING_PROPS.NOT_SET,
    origin: TRACKING_PROPS.ORIGIN_FIND_DETAIL_MWEB_SCREEN,
    position: TRACKING_PROPS.NOT_SET,
    autocompleteSuggestionQuantity: TRACKING_PROPS.NOT_SET,
    searched: TRACKING_PROPS.NOT_SET,
  },
  track: ({
    action,
    searchType,
    businessType,
    autocompleteSuggestionQuantity,
    position,
    searched,
    origin,
  }) => {
    tracker.track(KEYS.FIND_DETAIL_CLICKED, {
      action,
      searchType,
      businessType,
      origin,
      position,
      autocompleteSuggestionQuantity,
      searched,
    });
  },
});

export { createTrackSuggestionShown, createSearchClickedTracker };
