/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import upSuggestion from '../../assets/icons/upSuggestion.svg';
import searchIcon from '../../assets/icons/search.svg';
import { capitalize } from '../../utils/string';
import debounce from '../../utils/utils';

import { fetchSuggestions } from '../../services/suggestions/fetchSuggestions';
import { NoResultsPlaceholder } from './components/NoResultPlaceholder';

import {
  IconDiv,
  SuggestionDiv,
  ItemTitle,
  ResultsDiv,
  SpanRegularTitle,
  SpanBoldTitle,
} from '../../FindDetail.styles';

import { TRACKING_PROPS } from '../../tracking/constants';
import { createTrackSuggestionShown } from '../../tracking/trackingHelper';

export const SuggestionsResults = ({
  inputValue = '',
  businessType = '',
  onChangeValue,
  submitSearch,
  tracker,
}) => {
  const trackSuggestionShown = useMemo(() => createTrackSuggestionShown(tracker), [tracker]);

  const suggestionsQuery = useQuery(
    ['suggestions', businessType, inputValue],
    () => fetchSuggestions(businessType, inputValue),
    { enabled: Boolean(inputValue), retry: false },
  );

  const prevSuggestions = usePrevious(suggestionsQuery.data);
  const suggestions = suggestionsQuery.data;

  const trackSuggestionShownDebounced = useMemo(
    () => debounce(trackSuggestionShown, 2000),
    [trackSuggestionShown],
  );

  useEffect(() => {
    if (prevSuggestions !== suggestions) {
      trackSuggestionShownDebounced({
        searchUserInput: inputValue,
        businessType,
        suggestionsLength: suggestions?.length,
      });
    }
  }, [trackSuggestionShownDebounced, suggestions, inputValue, businessType, prevSuggestions]);

  if (suggestionsQuery.isError || suggestionsQuery.isLoading) return null;

  if (!suggestions?.length) return <NoResultsPlaceholder businessType={businessType} />;

  const highlightText = (text) => {
    if (!text) return;
    const parts = text.split(new RegExp(`(${inputValue})`, 'gi')).filter((p) => p);
    // eslint-disable-next-line consistent-return
    return parts.map((part, index) =>
      part.toLowerCase() === inputValue.toLowerCase() && index === 0 ? (
        <SpanRegularTitle key={`${part}-${index}-${text}-regular`}>
          {capitalize(part)}
        </SpanRegularTitle>
      ) : (
        <SpanBoldTitle key={`${part}-${index}-${text}-bold`}>{part}</SpanBoldTitle>
      ),
    );
  };

  return (
    <ResultsDiv>
      {suggestions.map((suggestionItem, position) => (
        <SuggestionDiv key={suggestionItem?.keyword}>
          <IconDiv>
            <img src={searchIcon} />
          </IconDiv>
          <ItemTitle
            onClick={() => {
              trackSuggestionShownDebounced.cancel();
              trackSuggestionShown({
                searchUserInput: inputValue,
                businessType,
                suggestionsLength: suggestions?.length,
              });
              submitSearch(suggestionItem.keyword, {
                position: position + 1,
                searchType: TRACKING_PROPS.SEARCH_AUTOCOMPLETE,
                autocompleteSuggestionQuantity: suggestions?.length,
                searched: suggestionItem?.keyword,
              });
            }}
          >
            {highlightText(suggestionItem.keyword)}
          </ItemTitle>
          <IconDiv onClick={() => onChangeValue(capitalize(suggestionItem.keyword))}>
            <img src={upSuggestion} />
          </IconDiv>
        </SuggestionDiv>
      ))}
    </ResultsDiv>
  );
};

SuggestionsResults.propTypes = {
  inputValue: PropTypes.string,
  businessType: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  submitSearch: PropTypes.func.isRequired,
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
};
