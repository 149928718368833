import { useState, useEffect } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import useMountedState from 'react-use/lib/useMountedState';

export const useRecentSearches = ({ key = '', maxAmount = 1 }) => {
  const isMounted = useMountedState();
  const [searches, setSearches] = useState({});
  const [searchesInStorage, setSearchesInStorage] = useLocalStorage(`recent-searches-${key}`, {});

  /**
   * Searches should be read from local storage only if the component is mounted
   * to keep compatibility with server side rendered html
   */
  useEffect(() => {
    if (!isMounted) return;
    setSearches(searchesInStorage);
  }, [isMounted, searchesInStorage]);

  const add = (businessType, value) => {
    const searchesForBusinessType = searchesInStorage[businessType];
    if (!searchesForBusinessType) {
      setSearchesInStorage({ ...searchesInStorage, [businessType]: [value] });
    } else if (!searchesForBusinessType.includes(value)) {
      if (searchesForBusinessType?.length === maxAmount) {
        searchesForBusinessType.pop();
      }
      setSearchesInStorage({
        ...searchesInStorage,
        [businessType]: [value, ...searchesForBusinessType],
      });
    }
  };

  const remove = (businessType = '', value = '') => {
    const searchesForBusinessType = searchesInStorage[businessType];
    if (!searchesForBusinessType) return;
    setSearchesInStorage({
      ...searchesInStorage,
      [businessType]: searchesForBusinessType.filter((s) => s !== value),
    });
  };

  return { searches, add, remove };
};
