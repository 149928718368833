import { defineMessages } from 'react-intl';

export default defineMessages({
  restaurantTitle: {
    id: 'app.shared.components.search.restaurant',
    defaultMessage: 'restaurantes',
  },
  groceriesTitle: {
    id: 'app.shared.components.search.groceries',
    defaultMessage: 'mercados',
  },
  restaurantNoInputPlaceHolder: {
    id: 'app.shared.components.search.NoInputPlaceHolder.restaurant',
    defaultMessage: 'Busca lo que quieras. Nosotros te lo llevamos.',
  },
  groceriesNoInputPlaceHolder: {
    id: 'app.shared.components.search.NoInputPlaceHolder.groceries',
    defaultMessage: 'Busca lo que necesites. Nosotros te lo llevamos.',
  },
  defaultNoInputPlaceHolder: {
    id: 'app.shared.components.search.NoInputPlaceHolder.default',
    defaultMessage: 'Busca lo que quieras. Nosotros te lo llevamos.',
  },
  restaurantNoSuggestionResults: {
    id: 'app.shared.components.search.NoSuggestions.restaurant',
    defaultMessage: 'No encontramos restaurantes para tu búsqueda.',
  },
  groceriesNoSuggestionResults: {
    id: 'app.shared.components.search.NoSuggestions.groceries',
    defaultMessage: 'No encontramos mercados para tu búsqueda.',
  },
  defaultNoSuggestionResults: {
    id: 'app.shared.components.search.NoSuggestions.default',
    defaultMessage: 'No encontramos resultados para tu búsqueda.',
  },
  inputPlaceHolder: {
    id: 'app.shared.components.search.InputPlaceHolder',
    defaultMessage: 'Buscar',
  },
  recentSearchesTitle: {
    id: 'app.shared.components.search.RecentSearchesTitle',
    defaultMessage: 'Búsquedas recientes',
  },
});
