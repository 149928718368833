import styled, { css } from 'styled-components';

export const Container = styled.div`
  font-family: Segma, sans-serif;
  font-size: 14px;
  font-weight: 800;
  overflow: hidden;
  background: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

export const StyledTab = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? `${width}%` : 'auto')};
  cursor: pointer;
  font-family: Segma, sans-serif;
  font-size: 16px;
  font-weight: 800;

  ${({ selected, theme }) =>
    selected &&
    css`
      border-bottom: 2px solid ${theme.color('shape-color-surface-secondary')};
    `}
`;

export const Text = styled.span`
  color: ${({ theme, selected }) =>
    selected
      ? theme.color('shape-color-surface-secondary')
      : theme.color('shape-color-surface-action-activated-focused')};

  padding: 14px;
`;
