/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { TRACKING_ORIGINS } from './constants';

const TrackerContext = React.createContext({});

export const useTracker = () => React.useContext(TrackerContext);

export const noop = { track: (...args) => console.log('No tracker implementation', ...args) };

export const TrackerProvider = ({ tracker = noop, origin, children }) => {
  const value = useMemo(
    () => ({ origin: origin || TRACKING_ORIGINS.DEEPLINK, tracker }),
    [tracker, origin],
  );

  if (!tracker) return null;

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
};

TrackerProvider.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  origin: PropTypes.oneOf(Object.values(TRACKING_ORIGINS)),
  children: PropTypes.node.isRequired,
};
