import styled from 'styled-components';

export const SwiperContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 3px;
  padding-bottom: 16px;
  & > * {
    flex: 0 0 auto;
    margin-right: 4%;
  }
  & > *:first-child {
    margin-left: 16px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  padding: 0;
  height: 100%;
  & > * {
    margin: 0;
  }
  & > *:first-child {
    margin: 0;
  }
`;

export const Container = styled.div`
  position: relative;

  padding: 0;
  height: 100%;
  & > * {
    margin: 0;
  }
  & > *:first-child {
    margin: 0;
  }
`;
