import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext } from 'react';
import axios from 'axios';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import BoxMessage from '@pedidosya/web-fenix/molecules/BoxMessage';
import { LoaderWrapper } from '../LoaderWrapper.styles';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await axios.get(`/find-detail/api/env`);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) return;
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error)
    return (
      <BoxMessage
        variant="quiet"
        type="error"
        title="Error"
        message="Tuvimos un problema"
        onClose={() => {}}
      />
    );

  if (!publicEnv)
    return (
      <LoaderWrapper>
        <BrandLoader />
      </LoaderWrapper>
    );

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
