import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledTab, Text } from '../../Tabs.styles';

function Tab({ id, onClick, selected, text, width }) {
  const tabRef = useRef(null);

  const handleClick = useCallback(() => {
    onClick(id, tabRef.current);
  }, [onClick, id]);

  useEffect(() => {
    if (selected && tabRef.current) {
      tabRef.current.scrollIntoView({ inline: 'start' });
    }
  }, [selected, text]);

  return (
    <StyledTab selected={selected} onClick={handleClick} width={width} ref={tabRef}>
      <Text selected={selected}>{text}</Text>
    </StyledTab>
  );
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
