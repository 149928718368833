import React from 'react';
import PropTypes from 'prop-types';
import iconRecent from '../../assets/icons/iconRecent.svg';
import { capitalize } from '../../utils/string';

import { TRACKING_PROPS } from '../../tracking/constants';
import { NoInputPlaceHolder } from './components/NoInputPlaceholder';
import {
  IconDiv,
  SuggestionDiv,
  ItemTitle,
  ResultsDiv,
  RecentSearchDiv,
  RecentSearchTitle,
  SpanRegularTitle,
} from '../../FindDetail.styles';

import closeIcon from '../../assets/icons/close.svg';

export const RecentSearches = ({
  submitSearch,
  recentSearches = [],
  removeRecentSearch,
  businessType = '',
}) => {
  if (!recentSearches.length) return <NoInputPlaceHolder businessType={businessType} />;
  return (
    <ResultsDiv>
      <RecentSearchDiv>
        <RecentSearchTitle>Búsquedas Recientes</RecentSearchTitle>
      </RecentSearchDiv>
      {recentSearches.map((search, position) => (
        <SuggestionDiv key={search}>
          <IconDiv>
            <img src={iconRecent} />
          </IconDiv>
          <ItemTitle
            onClick={() => {
              submitSearch(search, {
                searchType: TRACKING_PROPS.SEARCH_RECENT,
                autocompleteSuggestionQuantity: recentSearches.length,
                position: position + 1,
                searched: search,
              });
            }}
          >
            <SpanRegularTitle>{capitalize(search)}</SpanRegularTitle>
          </ItemTitle>
          <IconDiv role="button" onClick={() => removeRecentSearch(search)}>
            <img src={closeIcon} />
          </IconDiv>
        </SuggestionDiv>
      ))}
    </ResultsDiv>
  );
};
RecentSearches.propTypes = {
  submitSearch: PropTypes.func.isRequired,
  recentSearches: PropTypes.arrayOf(PropTypes.string),
  removeRecentSearch: PropTypes.func.isRequired,
  businessType: PropTypes.string,
};
