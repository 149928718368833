import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import peopleRiderOdBike from '../../../assets/icons/peopleRidersOdBike.svg';
import ordersPackaging from '../../../assets/icons/ordersPackaging.svg';

import messages from '../../../messages/messages';

import { BUSINESS_TYPES } from '../../../constants';

import { ScreenImage, ScreenText } from '../../../FindDetail.styles';

export const NoInputPlaceHolder = ({ businessType }) => {
  if (businessType === BUSINESS_TYPES.RESTAURANT) {
    return (
      <>
        <ScreenImage src={peopleRiderOdBike} alt="People Rider" />
        <ScreenText>
          <FormattedMessage {...messages.restaurantNoInputPlaceHolder}>
            {(text) => text}
          </FormattedMessage>
        </ScreenText>
      </>
    );
  }

  if (businessType === BUSINESS_TYPES.GROCERIES) {
    return (
      <>
        <ScreenImage src={ordersPackaging} alt="Order Package" />
        <ScreenText>
          <FormattedMessage {...messages.groceriesNoInputPlaceHolder}>
            {(text) => text}
          </FormattedMessage>
        </ScreenText>
      </>
    );
  }
  return (
    <>
      <ScreenImage src={peopleRiderOdBike} alt="People Rider" />
      <ScreenText>
        <FormattedMessage {...messages.defaultNoInputPlaceHolder}>
          {(text) => text}
        </FormattedMessage>
      </ScreenText>
    </>
  );
};

NoInputPlaceHolder.propTypes = {
  businessType: PropTypes.string.isRequired,
};
