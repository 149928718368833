import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, StaticRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

// Internal Modules and Utilities
import { TrackerProvider } from './contexts/tracker';
import { createIntlProvider, getMessages } from './contexts/IntlProvider';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from './contexts/PublicEnv';
import { logger } from './logger';
import { getHistory } from './modules/FindDetail/utils/history';

// Styles and Themes
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import GlobalStyles from './modules/FindDetail/theme/globalStyleSheet';

// Components
import FindDetail from './modules/FindDetail/FindDetail';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const LogsProvider = ({ children }) => {
  const { user, deviceInfo, environment, country, device } = usePublicEnv();

  useEffect(() => {
    try {
      logger.init({ dsn: SENTRY_DSN, environment });
      logger.setUser({ id: user?.id, email: user?.email });
      logger.setTag('country', country.shortName);
      logger.setTag('device', device);
      logger.setTag('appVersion', deviceInfo?.appVersion);
      logger.setTag('os', deviceInfo?.os);
      logger.setTag('deviceType', deviceInfo?.type);
    } catch (error) {
      console.error('Error initializing sentry hub', error.message);
    }
  }, [environment, user, country, device, deviceInfo]);

  return children;
};

const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function AppRouter({ url, history, children }) {
  if (typeof window === 'undefined')
    return (
      <StaticRouter location={url} context={{}}>
        {children}
      </StaticRouter>
    );

  return <Router history={history}>{children}</Router>;
}

const Root = ({ context, messages, url, userEmail, tracker, queryClient = defaultQueryClient }) => {
  const query = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
  const trackingOrigin = query.get('origin');
  return (
    <TrackerProvider tracker={tracker} origin={trackingOrigin}>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <QueryClientProvider client={queryClient}>
          <LogsProvider>
            <FenixFonts />
            <ThemeProvider theme={fenixTheme}>
              <GlobalStyles />
              <AppRouter url={url} history={getHistory()}>
                <IntlProvider initialMessages={messages}>
                  <FindDetail userEmail={userEmail} tracker={tracker} />
                </IntlProvider>
              </AppRouter>
            </ThemeProvider>
          </LogsProvider>
        </QueryClientProvider>
      </PublicEnvProvider>
    </TrackerProvider>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};
Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
