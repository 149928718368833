import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { injectIntl } from 'react-intl';

import { businessTypesMap, BUSINESS_TYPES, maxAmountRecentSearches } from './constants';
import { Container } from './FindDetail.styles';

import { FindDetailHeader } from './components/FindDetailHeader/FindDetailHeader';
import { SuggestionsResults } from './components/SuggestionsResults/SuggestionsResults';
import { RecentSearches } from './components/RecentSearches/RecentSearches';
import { useRecentSearches } from './hooks/useRecentSearches';
import { createSearchClickedTracker } from './tracking/trackingHelper';
import { usePublicEnv } from '../../contexts/PublicEnv';

export const FindDetailComponent = ({ userEmail, tracker, intl: { formatMessage } }) => {
  const history = useHistory();
  const { search } = useLocation();

  const [businessType, setBusinessType] = useState(BUSINESS_TYPES.RESTAURANT);
  const [inputValue, setInputValue] = React.useState(queryString.parse(search)?.query || '');

  const recentSearches = useRecentSearches({ key: userEmail, maxAmount: maxAmountRecentSearches });

  const { customize } = usePublicEnv();
  const { locationData } = customize;

  /** Business types from query */
  const getBusinessTypeById = (id) => ({ id, name: formatMessage(businessTypesMap.get(id)) });
  const businessTypesParams = queryString.parse(search)?.businessTypes;
  const businessTypes = Array.from(businessTypesParams?.split(',') || []).map(getBusinessTypeById);

  const updateQueryParam = useCallback(
    (newValue) => {
      const params = queryString.parse(search);
      params.query = newValue;
      if (!params.query) delete params.query;
      history.replace({ search: queryString.stringify(params) });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history],
  );

  useEffect(() => updateQueryParam(inputValue), [inputValue, updateQueryParam]);

  const searchClickedTracker = createSearchClickedTracker(tracker);

  const submitSearch = (valueToSearch, trackingProps) => {
    searchClickedTracker.track({
      ...searchClickedTracker.defaultProps,
      searched: inputValue,
      businessType,
      ...trackingProps,
    });
    updateQueryParam(valueToSearch);

    const { latitude, longitude } = locationData || {};

    const searchParams = {
      bt: businessType,
      lat: latitude,
      lng: longitude,
      modal: 'search',
      query: valueToSearch,
    };
    recentSearches.add(businessType, valueToSearch);
    const url = `/restaurantes?${queryString.stringify(searchParams)}`;
    window.location.href = url;
  };

  return (
    <Container>
      <FindDetailHeader
        businessType={businessType}
        businessTypes={businessTypes}
        setBusinessType={setBusinessType}
        inputValue={inputValue}
        onChangeValue={setInputValue}
        submitSearch={submitSearch}
        tracker={tracker}
      />
      {inputValue ? (
        <SuggestionsResults
          inputValue={inputValue}
          businessType={businessType}
          onChangeValue={setInputValue}
          submitSearch={submitSearch}
          tracker={tracker}
        />
      ) : (
        <RecentSearches
          businessType={businessType}
          submitSearch={submitSearch}
          recentSearches={recentSearches.searches[businessType]}
          removeRecentSearch={(value) => recentSearches.remove(businessType, value)}
        />
      )}
    </Container>
  );
};

FindDetailComponent.propTypes = {
  userEmail: PropTypes.string,
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
};

const FindDetail = injectIntl(FindDetailComponent);

export { FindDetail };
export default FindDetail;
