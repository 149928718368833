import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { TRACKING_PROPS } from '../../tracking/constants';

import arrowLeftIcon from '../../assets/icons/arrow-left.svg';
import closeIcon from '../../assets/icons/close.svg';
import searchIcon from '../../assets/icons/search.svg';

import {
  Form,
  Input,
  InputBox,
  IconDiv,
  Div,
  BackButton,
  DivTab,
  CircleDiv,
  Header,
} from '../../FindDetail.styles';

import Tabs from '../Tabs/Tabs';
import { createSearchClickedTracker } from '../../tracking/trackingHelper';

export const FindDetailHeader = ({
  businessType,
  businessTypes,
  setBusinessType,
  inputValue,
  onChangeValue,
  submitSearch,
  tracker,
}) => {
  const searchClickedTracker = createSearchClickedTracker(tracker);

  const selectTab = (selectedTab) => {
    if (businessType !== selectedTab) {
      searchClickedTracker.track({
        ...searchClickedTracker.defaultProps,
        action: TRACKING_PROPS.CHANGE_TAB,
        businessType: selectedTab,
        searched: inputValue,
      });
    }
    setBusinessType(selectedTab);
  };

  return (
    <Header>
      <BackButton role="button" onClick={() => (window.location.href = '/home-page')}>
        <img src={arrowLeftIcon} width="24px" height="24px" />
      </BackButton>
      {businessTypes.length > 1 && (
        <DivTab>
          <Tabs tabSelectedId={businessType} tabs={businessTypes} onSelect={selectTab} />
        </DivTab>
      )}
      <Div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitSearch(inputValue, {
              searchType: TRACKING_PROPS.SEARCH_BUTTON,
            });
          }}
        >
          <Input>
            <IconDiv>
              <img src={searchIcon} />
            </IconDiv>
            <InputBox
              placeholder="Buscar"
              value={inputValue}
              onChange={(e) => onChangeValue(e.target.value)}
            />
            {inputValue !== '' && (
              <IconDiv>
                <CircleDiv role="button" onClick={() => onChangeValue('')}>
                  <img src={closeIcon} width="12px" height="12px" />
                </CircleDiv>
              </IconDiv>
            )}
          </Input>
        </Form>
      </Div>
    </Header>
  );
};

FindDetailHeader.propTypes = {
  businessType: PropTypes.string.isRequired,
  businessTypes: PropTypes.array.isRequired,
  setBusinessType: PropTypes.func.isRequired,
  submitSearch: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
};
